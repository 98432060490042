import { IMAGE_ROOT_URL, ROOT_URL } from 'constants/url-constants';

export const PageMeta = ({ page }) => (
  <>
    <meta name="description" content={page.MetaDescription} />
    <meta property="og:title" content={page.OgTitle} />
    <meta property="og:description" content={page.OgDescription} />
    <meta property="og:image" content={IMAGE_ROOT_URL + page.OgImage} />
    <meta property="og:type" content="article" />
    <meta property="og:url" content={ROOT_URL + page.Url} />
  </>
);
