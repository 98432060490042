import Image from 'next/image';

export const PaymentMethods = () => (
  <div className="text-center">
    <Image
      className="inline-block"
      alt="Betalingskort"
      src="/images/creditcards.png"
      height={25}
      width={206}
    />
  </div>
);
