import { Membership } from 'types/Membership';

interface Props {
  monthly: Membership;
  yearly: Membership;
}

export const memberships: Props = {
  monthly: {
    shortName: 'Månedligt',
    longName: 'Månedligt medlemskab',
    price: '49 kr',
    period: '/md.',
    popular: false
  },
  yearly: {
    shortName: 'Årligt',
    longName: 'Årligt medlemskab',
    price: '490 kr',
    period: '/år.',
    popular: true
  }
};
