import { fetcher, postRequest } from './fetch-helper';

// TODO: Refactor with FormikValues in PurchaseMembership
interface SubcribeModel {
  type: 'monthly' | 'yearly';
  email: string;
  name: string;
  acceptTerms: boolean;
}

export const subscribeRequest = async (data: SubcribeModel) =>
  fetcher('/SubscribeV2/Subscribe', postRequest(data));

// TODO: Refactor with FormikValues in velkommen
interface CompleteModel {
  Key: string;
  Ticks: string;
  Checksum: string;
  Age: string;
  Zipcode: string;
  Gender: string;
}

export const completeSubscribeRequest = async (data: CompleteModel) =>
  fetcher('/SubscribeV2/Complete', postRequest(data));
