import { Formik } from 'formik';
import { AnimatePresence, motion } from 'framer-motion';
import { subscribeRequest } from 'hooks/useSubscribe';
import { useCallback, useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import * as Yup from 'yup';
import { Step1 } from './MembershipStep1';
import { Step2 } from './MembershipStep2';

interface FormikValues {
  type: 'monthly' | 'yearly';
  email: string;
  name: string;
  acceptTerms: boolean;
}

const schema = Yup.object().shape({
  name: Yup.string().required('Husk at indtaste dit fulde navn.'),
  email: Yup.string()
    .email('Den indtastede e-mail adresse er ugyldig.')
    .required('Husk at indtaste din e-mail adresse.'),
  acceptTerms: Yup.boolean().oneOf(
    [true],
    'Husk at læse og acceptere vores betingelser.'
  )
});

const variants = {
  initial: (direction: number) => {
    return {
      x: direction > 0 ? '100%' : '-100%'
    };
  },
  enter: {
    x: 0
  },
  exit: (direction: number) => {
    return {
      x: direction < 0 ? '100%' : '-100%'
    };
  }
};

export default function PurchaseMembership() {
  const {
    mutate: subscribe,
    data,
    isLoading,
    isError,
    isSuccess,
    error
  } = useMutation(subscribeRequest);

  const containerRef = useRef<HTMLDivElement>(null);
  const [[page, direction], setPage] = useState([0, 0]);
  const goToPage = useCallback(
    (newDirection: number) => {
      setPage([page + newDirection, newDirection]);
    },
    [page]
  );

  // On success
  useEffect(() => {
    if (isSuccess) {
      window.location.href = data.url;
    }
  }, [isSuccess, data]);

  // On error
  useEffect(() => {
    if (isError) {
      const errorMessage = (error as any)?.Message || 'Der skete en fejl';
      toast.error(errorMessage, {
        duration: 5000
      });
    }
  }, [isError, error]);

  const initialValues: FormikValues = {
    type: 'yearly',
    name: '',
    email: '',
    acceptTerms: false
  };

  return (
    <Formik
      validationSchema={schema}
      initialValues={initialValues}
      onSubmit={(values) => {
        subscribe(values);
      }}
    >
      {({ status, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <h2 className="font-bold text-3xl md:text-4xl text-center mb-4 md:mb-4">
            Bliv medlem af
            <br className="md:hidden" /> Klub Vollmer
          </h2>

          <p className="font-semibold md:text-xl text-center mb-8 mx-auto w-2/3">
            ...og om to minutter kan du være i gang med at lære en lækker ret,
            som du kan forkæle din familie med allerede i aften.
          </p>

          <div
            ref={containerRef}
            className="relative mx-6 md:mx-auto max-w-3xl justify-center overflow-hidden"
          >
            <AnimatePresence exitBeforeEnter initial={false} custom={direction}>
              <motion.div
                key={page}
                custom={direction}
                variants={variants}
                initial="initial"
                animate="enter"
                exit="exit"
                transition={{ type: 'linear' }}
              >
                {page === 0 && <Step1 goToPage={goToPage} key="step-1" />}
                {page === 1 && (
                  <Step2
                    goToPage={goToPage}
                    isLoading={isLoading}
                    isSuccess={isSuccess}
                    key="step-2"
                  />
                )}
              </motion.div>
            </AnimatePresence>
          </div>
        </form>
      )}
    </Formik>
  );
}
