import { Button, ButtonLink } from '@/components/Button';
import { ACCOUNT_SIGN_IN_URL, TERMS_URL } from 'constants/url-constants';
import { useField } from 'formik';
import { useFormikHelper } from 'hooks/useFormikHelper';
import Link from 'next/link';
import { Membership, MembershipType } from 'types/Membership';
import { FormikCheckbox, FormikTextfield } from '../FormikFields';
import { PaymentMethods } from '../PaymentMethods/PaymentMethods';
import { memberships } from './membershipTypes';

export const Step1 = ({ goToPage }) => {
  const { validateAndTouch } = useFormikHelper();

  const handlegGoToStepTwoClick = async () => {
    const isValid = await validateAndTouch();
    if (isValid) {
      goToPage(1);
    }
  };

  // const { validateForm, setTouched } = useFormikContext();
  // const handlegGoToStepTwoClick = async () => {
  //   const errors = await validateForm();
  //   const isValid = Object.keys(errors).length === 0;
  //   if (isValid) {
  //     goToPage(1);
  //   } else {
  //     setTouched(
  //       setNestedObjectValues<FormikTouched<any>>(errors, true),
  //       false
  //     );
  //   }
  // };

  return (
    <>
      <div className="flex flex-col items-center justify-center space-y-8 md:flex-row md:items-stretch md:space-y-0 mb-7 md:mb-5">
        <ProductBox type="monthly" className="md:mr-2" tabIndex={1}>
          <ProductBoxList>
            <ProductBoxListItem>
              <span className="font-bold">5% rabat</span> på alle dine køb i
              Klub Vollmer Shop nu og 10% rabat efter 3 mdr *
            </ProductBoxListItem>
            <ProductBoxListItem>
              Adgang med det samme til 200+ opskriftsvideoer
            </ProductBoxListItem>
            <ProductBoxListItem>
              En ny opskriftsvideo hver uge
            </ProductBoxListItem>
            <ProductBoxListItem>
              Masser af kokketips og -tricks
            </ProductBoxListItem>
            <ProductBoxListItem>
              Få Jespers hjælp til opskriftspørgsmål og -ønsker
            </ProductBoxListItem>
            <ProductBoxListItem>Events kun for medlemmer</ProductBoxListItem>
          </ProductBoxList>
        </ProductBox>

        <ProductBox type="yearly" className="md:ml-2" tabIndex={2}>
          <ProductBoxList>
            <ProductBoxListItem>
              <span className="font-bold">10% rabat</span> på alle dine køb i
              Klub Vollmer Shop med det samme *
            </ProductBoxListItem>
            <ProductBoxListItem>
              Adgang med det samme til 200+ opskriftsvideoer
            </ProductBoxListItem>
            <ProductBoxListItem>
              En ny opskriftsvideo hver uge
            </ProductBoxListItem>
            <ProductBoxListItem>
              Masser af kokketips og -tricks
            </ProductBoxListItem>
            <ProductBoxListItem>
              Få Jespers hjælp til opskriftspørgsmål og -ønsker
            </ProductBoxListItem>
            <ProductBoxListItem>Events kun for medlemmer</ProductBoxListItem>
          </ProductBoxList>
        </ProductBox>
      </div>

      <div className="grid md:grid-cols-2 md:gap-5 mb-3">
        <FormikTextfield
          label="Dit fulde navn *"
          name="name"
          type="text"
          tabIndex={3}
        />
        <FormikTextfield
          label="Din e-mail *"
          name="email"
          type="email"
          tabIndex={4}
        />
      </div>

      <FormikCheckbox name="acceptTerms" tabIndex={5}>
        Jeg accepterer{' '}
        <Link href={TERMS_URL}>
          <a
            className="underline hover:no-underline"
            target="_blank"
            tabIndex={-1}
          >
            handelsbetingelserne
          </a>
        </Link>
        .
      </FormikCheckbox>

      <div className="mb-8">
        <Button
          onClick={handlegGoToStepTwoClick}
          className="w-full py-3"
          type="button"
          tabIndex={6}
        >
          Fortsæt
        </Button>
      </div>

      <p className="mb-3 text-center">
        <ButtonLink url={ACCOUNT_SIGN_IN_URL}>
          Allerede medlem? Log ind her
        </ButtonLink>
      </p>

      <p className="text-sm text-center mb-4">
        Opsig når du vil, til udgangen af indeværende abonnementsperiode.
        <br />
        *Medlemsrabat gælder ikke ved køb af gavekort til Klub Vollmer Shop.
      </p>

      <PaymentMethods />
    </>
  );
};

interface ProductBoxProps {
  type: MembershipType;
  className?: string;
  tabIndex: number;
  children: React.ReactNode;
}

const ProductBox = ({
  type,
  className,
  tabIndex,
  children
}: ProductBoxProps): JSX.Element => {
  const [field, , helpers] = useField({ name: 'type' });
  const isSelected = field.value === type;
  const { shortName, price, period, popular } = memberships[type] as Membership;

  return (
    <a
      className={`
        flex flex-col w-full py-8 px-6 space-y-6 bg-white border cursor-pointer rounded-xl shadow-sm
        ${
          isSelected
            ? ' border-gray-500 shadow-md shadow-blue-200'
            : ' border-white hover:shadow-md'
        }
        ${className}
    `}
      onClick={() => helpers.setValue(type)}
      onKeyPress={(event) => {
        if (event.key === 'Enter') {
          helpers.setValue(type);
        }
      }}
      tabIndex={tabIndex}
    >
      <h2 className="text-center px-2 font-semibold uppercase">{shortName}</h2>

      <div className="text-center">
        <span className="pt-2 text-2xl font-bold text-gray-800 mr-1">
          {price}
        </span>

        <span className="text-gray-500">{period}</span>

        <div className="my-3">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4 fill-blue-500 mx-auto"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 512 512"
          >
            {isSelected ? (
              <path d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM371.8 211.8C382.7 200.9 382.7 183.1 371.8 172.2C360.9 161.3 343.1 161.3 332.2 172.2L224 280.4L179.8 236.2C168.9 225.3 151.1 225.3 140.2 236.2C129.3 247.1 129.3 264.9 140.2 275.8L204.2 339.8C215.1 350.7 232.9 350.7 243.8 339.8L371.8 211.8z" />
            ) : (
              <path d="M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z" />
            )}
          </svg>
        </div>

        <p className="mt-2 leading-tight text-sm text-gray-500">
          Ingen binding.
          <br />
          Opsig når som helst.
        </p>

        {popular ? (
          <LabelBadge className="text-emerald-600 bg-emerald-200">
            2 måneder gratis hvert år
          </LabelBadge>
        ) : (
          // Spacer used to align list items
          <LabelBadge className="hidden md:block">&nbsp;</LabelBadge>
        )}
      </div>

      {children}
    </a>
  );
};

const ProductBoxList = ({ children }) => (
  <ul className="flex-1 space-y-4">{children}</ul>
);

const ProductBoxListItem = ({ children }) => (
  <li className="flex">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-3.5 w-3.5 flex-none fill-green-500 mt-1 mr-2.5"
      fill="none"
      stroke="currentColor"
      viewBox="0 0 512 512"
    >
      <path d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z" />
    </svg>

    <p className="flex-1 text-sm m-0">{children}</p>
  </li>
);

const LabelBadge = ({ className, children }) => (
  <span
    className={`
      text-xs font-semibold inline-block py-1 px-2 rounded-full uppercase mt-3
      ${className}`}
  >
    {children}
  </span>
);
