import { useField } from 'formik';
import { Button } from '@/components/Button';
import { memberships } from './membershipTypes';
import { PaymentMethods } from '../PaymentMethods/PaymentMethods';
import { Membership } from 'types/Membership';

interface Props {
  goToPage: (page: number) => void;
  isSuccess: boolean;
  isLoading: boolean;
}

export const Step2 = ({ goToPage, isLoading, isSuccess }: Props) => {
  const [typeField] = useField('type');
  const [nameField] = useField('name');
  const [emailField] = useField('email');
  const { longName, price, period } = memberships[
    typeField.value
  ] as Membership;

  return (
    <div className="md:mx-40">
      <div className="bg-white p-5 pt-10 text-center rounded-xl shadow-sm mb-5">
        <p className="text-gray-500 mb-0">Din bestilling</p>
        <h2 className="text-center font-semibold uppercase mb-4">{longName}</h2>
        <p className="mb-8">
          <span className="pt-2 text-2xl font-bold text-gray-800 mr-1">
            {price}
          </span>
          <span className="text-gray-500">{period}</span>
        </p>

        <p className="text-gray-500 mb-10">
          {nameField.value}
          <br />
          {emailField.value}
        </p>

        <div className="mb-5">
          <Button
            type="submit"
            className="w-full"
            disabled={isLoading || isSuccess}
            isLoading={isLoading || isSuccess}
          >
            Gå til betaling
          </Button>
        </div>

        <PaymentMethods />
      </div>

      <button
        className="block text-sm mx-auto underline hover:no-underline"
        type="button"
        onClick={() => goToPage(-1)}
      >
        Tilbage til bestilling
      </button>
    </div>
  );
};
